import * as React from "react"
import { useStaticQuery, graphql, navigate } from "gatsby"
// component
import Reel from "../../components/Reel"
// helper
import { getEdges } from "../../utils/helper"

const HomeSection = (props) => {
  const data = useStaticQuery(graphql`
    query FabricBase {
      allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/content/fabric_base/" } }
      ) {
        totalCount
        edges {
          node {
            fields {
              slug
            }
            frontmatter {
              title
              thumbnail
            }
          }
        }
      }
    }
  `)
  const baseFabrics = getEdges(data)?.map((i) => ({
    name: i.node.frontmatter.title,
    slug: i.node.fields.slug,
    img: i.node.frontmatter.thumbnail,
  }))

  return (
    <section id="home">
      <div className="container">
        <h2>World Of Fabrics</h2>
        <h1>Fabricsthan</h1>
        <p>
          Fabricsthan is a repository of fabrics in india. We have collated
          information and provided it in a quick and easy way. Do you want to
          explore the fabrics of different states? Click the link below to get
          started.
        </p>
        <button onClick={() => navigate("/fabrics-of-india")}>
          Fabrics Of India
        </button>
        <Reel items={baseFabrics} />
      </div>
    </section>
  )
}

export default HomeSection
