import * as React from "react"
import { useStaticQuery, graphql, navigate } from "gatsby"
// helper
import { getEdges } from "../../utils/helper"

const slugReplace = (slug) => slug.replace("fabric_type", "type")

const Type = ({ excerpt, name, slug, img, onHover, background }) => {
  const nav = () => navigate(slugReplace(slug))
  return (
    <div
      className="type"
      role="button"
      onClick={nav}
      onKeyDown={nav}
      tabIndex={0}
      onMouseEnter={() => onHover(background)}
      onMouseLeave={() => onHover(null)}
    >
      <img src={img} alt={name} />
      <div className="inner">
        <h3>{name}</h3>
        <p>{excerpt}</p>
        <button onClick={nav}>Read More</button>
      </div>
    </div>
  )
}

const FabricTypes = () => {
  const [background, setBackground] = React.useState(null)

  const data = useStaticQuery(graphql`
    query FabricType {
      allMarkdownRemark(
        filter: {
          fileAbsolutePath: { regex: "/.*content/fabric_type/.*.md$/" }
        }
      ) {
        totalCount
        edges {
          node {
            fields {
              slug
            }
            frontmatter {
              title
              excerpt
              thumbnail
              background
            }
          }
        }
      }
    }
  `)
  const fabricTypes = getEdges(data)?.map((i) => ({
    name: i.node.frontmatter.title,
    slug: i.node.fields.slug,
    img: i.node.frontmatter.thumbnail,
    excerpt: i.node.frontmatter.excerpt,
    background: i.node.frontmatter.background,
  }))

  const onHover = (bg) => setBackground(bg)

  return (
    <section
      id="fabric-types"
      className={background ? "white" : null}
      style={{ background: `url(${background})` }}
    >
      <div className="container">
        <h2>Types of Fabrics</h2>
        <p>Fabrics can be broadly classified into 3 major types:</p>
        <div id="types">
          {fabricTypes.map((type, i) => (
            <Type {...type} key={i} onHover={onHover} />
          ))}
        </div>
      </div>
    </section>
  )
}

export default FabricTypes
