import * as React from "react"
import aboutImage1 from "../../images/aboutFabricsthan1.png"
import aboutImage2 from "../../images/aboutFabricsthan2.png"

const About = () => {
  return (
    <section id="about">
      <div className="container">
        <h2>About Fabricsthan</h2>
        <div className="flex-container">
          <div className="left-container">
            <img src={aboutImage1} alt="About Fabricsthan" />
          </div>
          <div className="right-container">
            <p>
              Fabricsthan is a repository of fabrics in India. We've gathered
              and written information about fabric bases, fabric types and
              fabrics. You can view the weather conditions a fabric is to be
              worn in, the usual price range and get info about the history /
              origin and process.
            </p>
            <p>
              Curious enough to know more about these fabrics and styles? Want
              to learn how these fabrics are made from fibers? We have you
              covered. Feel free to contact us if you are looking to buy any of
              the fabrics mentioned on the site.
            </p>
            <p className="logo">Fabricsthan</p>
            <div className="image-container">
              <img src={aboutImage2} alt="About Fabricsthan" />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default About
