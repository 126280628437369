import * as React from "react"

const Contact = () => {
  return (
    <section id="contact">
      <div className="container">
        <h2>Contact Us</h2>
        <p>
          Want to advertise on our site or have something to tell us? Send us an
          email at{" "}
          <a href="mailto:fabricsthan@gmail.com">fabricsthan@gmail.com</a>
        </p>
        {/* Social Icons */}
      </div>
    </section>
  )
}

export default Contact
