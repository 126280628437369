import * as React from "react"
import HomeSection from "./HomeSection"
import FabricTypes from "./FabricTypes"
import FabricsOfIndia from "./FabricsOfIndia"
import About from "./About"
import Contact from "./Contact"
import "./main.scss"

const Main = () => {
  return (
    <main id="main">
      <HomeSection />
      <FabricTypes />
      <FabricsOfIndia />
      <About />
      <Contact />
    </main>
  )
}

export default Main
