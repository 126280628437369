import * as React from "react"
import { navigate, useStaticQuery, graphql, Link } from "gatsby"
// component
import Reel from "../../components/Reel"
// helper
import { getEdges } from "../../utils/helper"
import fabricsOfIndia from "../../images/fabricsOfIndia.png"

const FabricsOfIndia = (props) => {
  const data = useStaticQuery(graphql`
    query Fabric {
      allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/.*content/fabric/.*.md$/" } }
      ) {
        totalCount
        edges {
          node {
            fields {
              slug
            }
            frontmatter {
              title
              thumbnail
            }
          }
        }
      }
    }
  `)
  const fabrics = getEdges(data)?.map((i) => ({
    name: i.node.frontmatter.title,
    slug: i.node.fields.slug,
    img: i.node.frontmatter.thumbnail,
  }))

  return (
    <section id="fabrics-of-india">
      <div className="container">
        <h2>Fabrics Of India</h2>
        <div className="flex-container">
          <div className="left-container">
            <p>
              India is a land of diverse cultures and heritage. Every region of
              this land has unique and beautiful customs to offer. With varied
              customs come varied apparels. A tropical country that has coastal
              areas, deserts, fertile lands, monsoon-rich states, hardworking
              farmers, and highly talented craftsmen undoubtedly has to be rich
              with a huge variety of fibers.
            </p>
            <p>
              The fabrics and their making differ from region to region in this
              country. The extreme north of the country has freezing weather
              conditions and so offers the best of wools and warm clothes. The
              hot and humid weather of the south is best dealt with by its soft{" "}
              <Link to="/base/cotton">cotton</Link> and{" "}
              <Link to="/base/silk/">silk</Link> fabrics.
            </p>
            <p>
              India celebrates Unity in Diversity in each and every aspect,
              including its fabrics. A country known for its big fat weddings,
              showcases its culture through heavy, gorgeous yet elegant royal
              sarees and dresses. These weddings are incomplete without
              Kanjeevaram and Banarasis.
            </p>
            <p>
              The country’s indigenous fashion is not just restricted to women,
              even men enjoy it thoroughly. Today, India’s fashion and fabrics,
              both have gained international demand because of their spectacular
              beauty.
            </p>
            <p>
              Here are some states and the fabrics these states have to offer:
            </p>
            <button onClick={() => navigate("/fabrics-of-india")}>
              See Fabrics From India
            </button>
          </div>
          <div className="right-container">
            <img src={fabricsOfIndia} alt="Fabrics Of India" />
          </div>
        </div>
        <Reel items={fabrics} />
      </div>
    </section>
  )
}

export default FabricsOfIndia
